import React, { useEffect } from "react";
import Hero from "./hero";
import Whyvisit from "./whyvisit";
import Zone from "../Home/zone";
import Venue from "./venue";
import Promotion from "./promotion";

export default function Visitor(props) {
  useEffect(() => {
    document.title = "Furniture Mega Show - Visitor";
  }, []);
  return (
    <section id="visitor-page">
      <Hero />
      <Whyvisit />
      <Zone />
      <Promotion />
      <Venue />
    </section>
  );
}
