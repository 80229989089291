import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import Axios from "axios";

import Hotelcard from "./hotelcard";

export default function Promotion() {
  const { t, i18n } = useTranslation("translation", {
    keyPrefix: "visitor.venue",
  });

  const [data, setData] = useState([]);

  const url = process.env.REACT_APP_API_URI + process.env.REACT_APP_vst;

  const getHotelData = async () => {
    try {
      const response = await Axios.get(url + "/getHotels/" + i18n.language);
      setData(response.data);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    getHotelData();
  }, [i18n.language]);

  useEffect(() => {
    console.log(data);
  }, [data]);

  return (
    <section id="venue" className="my-36 px-8 md:px-12 lg:px-20">
      <div className="pb-12">
        <h2 className="w-full text-center text-[#2C348B] font-semibold text-2xl sm:text-3xl md:text-4xl">
          {t("subtitle4")}
        </h2>
        <div className="border-b mt-1 w-[320px] border-gray-500 mx-auto mb-7"></div>
      </div>
      <div className="mb-14 rounded-md md:rounded-xl overflow-hidden w-[90%] md:w-4/5 xl:w-3/4 mx-auto">
        <img
          src={require("./img/tier.jpg")}
          alt="tier"
          className="w-full object-contain"
        />
      </div>
    </section>
  );
}
