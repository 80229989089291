import React, { useEffect } from "react";
import { Route, Routes } from "react-router-dom";
import AppRoutes from "./AppRoutes";
import Layout from "./layout/Layout";
import "./custom.css";
import Prelaunch from "./Home/prelaunch";
import { useCookies } from "react-cookie";

import TagManager from "react-gtm-module";
import i18n from "./i18n";

export default function App(props) {
  const prelaunch = false;
  const version = process.env.REACT_APP_VERSION;
  const [cookies, setCookie, removeCookie] = useCookies([]);
  const cVersion = cookies.x_client_version;

  useEffect(() => {
    if ("caches" in window) {
      if (cVersion !== version) {
        caches.keys().then((names) => {
          // Delete all the cache files
          names.forEach((name) => {
            caches.delete(name);
          });
        });
        setCookie("x_client_version", version);
        window.location.reload(true);
      }
    }
  }, [cookies]);

  TagManager.initialize({ gtmId: "GTM-TNXV5QG" });
  TagManager.initialize({ gtmId: "GTM-KNX9WC4" });

  return (
    <Routes>
      {prelaunch && <Route path="/" element={<Prelaunch />} />}
      <Route element={<Layout />}>
        {AppRoutes.map((route, i) => (
          <Route key={i} path={route.path} element={route.element} />
        ))}
      </Route>
    </Routes>
  );
}
