import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import Axios from "axios";

import { FaCheck } from "react-icons/fa6";

export default function Whyvisit() {
  const { t, i18n } = useTranslation("translation", {
    keyPrefix: "visitor.visit",
  });

  const registClick = () => {
    let campaign = localStorage.getItem("campaign");
    if (campaign != null || campaign != undefined || campaign != "") {
      window.location.href =
        "https://www.worldfair.co.th/i125/preregistration/" + campaign;
    } else {
      window.location.href = "https://www.worldfair.co.th/i125/preregistration";
    }
  };

  return (
    <section id="whyExhibit">
      <div className="relative overflow-hidden">
        <div className="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2">
          <img
            src={require("../exhibitor/img/bg-circle.png")}
            alt="whyexhibit"
            className="w-fit"
          />
        </div>
        <div className="py-36 text-center">
          <h1 className="text-[#2C348B] font-semibold text-3xl sm:text-4xl md:text-5xl">
            {t("title")} <br />
            Furniture Mega Show
          </h1>
        </div>
      </div>
      <div className="bg-[#efefef] grid grid-cols-1 md:grid-cols-2 py-16 gap-4">
        <div className="sm:grid grid-cols-2 gap-2 md:relative w-full h-full">
          <div className="max-sm:hidden md:absolute top-0 left-0 md:w-[55%] md:max-xl:h-[60%] xl:w-3/5">
            <img
              src={require("../visitor/img/why1.jpg")}
              alt="whyexhibit"
              className="w-full h-full object-cover"
            />
          </div>
          <div className="md:absolute bottom-0 right-0 md:w-[55%] md:max-xl:h-[60%] xl:w-3/5">
            <img
              src={require("../visitor/img/why2.jpg")}
              alt="whyexhibit"
              className="w-full h-full object-cover"
            />
          </div>
        </div>
        <div>
          <div>
            <h2 className="text-[#2C348B] font-semibold text-2xl sm:text-3xl md:text-4xl">
              {t("subtitle")}
            </h2>
          </div>
          <ul className="pt-8 pb-6">
            {Array.from({ length: 5 }).map((_, index) => (
              <li
                key={"list" + index}
                className="mb-4 text-[#2C348B] text-xl font-medium"
              >
                <div className="flex items-center gap-4">
                  <FaCheck />
                  {t("list" + (index + 1))}
                </div>
              </li>
            ))}
          </ul>
          <div
            className="w-[300px] py-1 border-[#2C348B] border rounded-full text-[#2C348B] text-center hover:bg-[#2C348B] hover:text-[#efefef] hover:border-white"
            onClick={registClick}
          >
            {t("register")}
          </div>
        </div>
      </div>
    </section>
  );
}
