import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import Axios from "axios";

import { FaCheck } from "react-icons/fa6";

export default function Whyexhibit() {
  const { t, i18n } = useTranslation("translation", {
    keyPrefix: "exhibitor.exhibit",
  });

  const url = process.env.REACT_APP_API_URI + process.env.REACT_APP_ext;

  const [wlist, setWlist] = useState([]);

  const getWhyExhibit = async () => {
    try {
      const res = await Axios.get(url + "/whyexhibit/" + i18n.language);
      setWlist(res.data);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    getWhyExhibit();
  }, [i18n.language]);

  return (
    <section id="whyExhibit" className="relative overflow-hidden py-8 md:py-24">
      <div className="absolute top-8 left-0 z-0">
        <img
          src={require("./img/bg-circle.png")}
          alt="whyexhibit"
          className="w-4/5 max-w-fit"
        />
      </div>
      <div className="grid grid-cols-1 md:grid-cols-[1fr_1.5fr] place-items-center">
        <div className="order-last md:order-first grid gap-4 grid-cols-2 sm:grid-cols-3 md:grid-cols-2 place-items-center">
          {[1, 2, 3, 4, 5, 6].map((i) => (
            <div className="max-w-[200px] max-h-[200px]">
              <img
                key={i}
                src={require(`./img/whyex/img_${i}.png`)}
                alt="whyexhibit"
                className="w-full object-cover"
              />
            </div>
          ))}
        </div>
        <div className="p-4 md:p-8">
          <div className="text-[#2C348B] font-semibold text-2xl sm:text-3xl md:text-4xl">
            {t("title")}
            <br />
            Furniture Mega Show
          </div>
          <div className="mt-11">
            <ul>
              {wlist.map((w, i) => (
                <li key={i} className="mb-8">
                  <span className="text-[#2C348B] font-semibold text-lg sm:text-xl md:text-2xl">
                    <div className="flex gap-4 items-center">
                      <FaCheck />
                      {w.title}
                    </div>
                  </span>
                  <span className="text-[#7E7E7E]">{w.desc}</span>
                </li>
              ))}
            </ul>
          </div>
        </div>
      </div>
    </section>
  );
}
